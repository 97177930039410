
import { Component, Prop, PropSync, Ref, Watch } from "vue-property-decorator";
import SnackbarModule from "@/store/snackbarModule";
import { getModule } from "vuex-module-decorators";
import Validation from "@/mixins/validation";
import { VForm } from "@/types/vForm";
import {
  DeploymentUpdateModel,
  VenueModel,
  OrganisationModel,
} from "@/api/generated";
import api from "@/api";

const snackbarModule = getModule(SnackbarModule);

@Component
export default class UpdateDeploymentDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Prop(String) deploymentId!: string;
  @Ref() private readonly form!: VForm;

  private loading = false;
  private organisations: Array<OrganisationModel> = [];
  private model: DeploymentUpdateModel = {
    name: "",
    organisationId: "",
    adminPin: "",
  };

  @Watch("syncedDialog")
  private async onDialogClose() {
    if (!this.syncedDialog) {
      this.form?.reset();
    }

    const response = await api.DeploymentService.apiDeploymentDeploymentIdGet(
      this.deploymentId
    );
    this.model = response.data;
  }

  private async created() {
    this.getOrganisations();
  }

  private async getOrganisations() {
    const response = await api.OrganisationService.apiOrganisationGet();
    this.organisations = response.data;
  }

  private async onDeploymentUpdate() {
    if (!this.form?.validate()) {
      return;
    }

    try {
      this.loading = true;
      await api.DeploymentService.apiDeploymentDeploymentIdPut(
        this.deploymentId,
        this.model
      );
      snackbarModule.setSnackbarMessage("Deployment updated");
      this.$emit("updated");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to update deployment");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }
}
