
import api from "@/api";
import {
  DeploymentCreateModel,
  OrganisationModel,
  VenueModel,
} from "@/api/generated";
import Validation from "@/mixins/validation";
import SnackbarModule from "@/store/snackbarModule";
import { VForm } from "@/types/vForm";
import moment from "moment";
import { Component, PropSync, Ref, Watch } from "vue-property-decorator";
import { getModule } from "vuex-module-decorators";
import UserModule from "@/store/userModule";
import userRoleConstant from "@/Constants/userRoleConstant";

const snackbarModule = getModule(SnackbarModule);
const userModule = getModule(UserModule);

@Component
export default class CreateDeploymentDialog extends Validation {
  @PropSync("dialog", { type: Boolean }) syncedDialog!: boolean;
  @Ref() private readonly form!: VForm;
  private loading = false;
  private organisations: Array<OrganisationModel> = [];
  private venues: Array<VenueModel> = [];
  private model: DeploymentCreateModel = {
    name: "",
    venueId: "",
    adminPin: "",
    organisationId: "",
    scheduledStart: "",
    scheduledEnd: "",
  };

  private get adminPermissions() {
    return (
      userModule.userRole == userRoleConstant.systemAdministrator ||
      userModule.userRole == userRoleConstant.operationsManager
    );
  }

  private get startDatePickerSettings() {
    return {
      max: this.model.scheduledEnd
        ? moment(this.model.scheduledEnd).format("yyyy-MM-DD")
        : "",
    };
  }
  private get endDatePickerSettings() {
    return {
      min: this.model.scheduledStart
        ? moment(this.model.scheduledStart).format("yyyy-MM-DD")
        : "",
    };
  }
  private get startTimePickerSettings() {
    return {
      format: "24hr",
    };
  }
  private get endTimePickerSettings() {
    return {
      format: "24hr",
    };
  }

  @Watch("syncedDialog")
  private onDialogClose() {
    if (!this.syncedDialog) {
      return;
    }

    this.form?.reset();
  }

  private created() {
    this.getOrganisations();
    this.getVenues();
  }

  private async getOrganisations() {
    const response = await api.OrganisationService.apiOrganisationGet();
    this.organisations = response.data;
  }

  private async getVenues() {
    const response = await api.VenueService.apiVenueGet();
    this.venues = response.data;
  }

  private async onDeploymentCreate() {
    if (!this.form.validate()) {
      return;
    }

    try {
      this.loading = true;
      await api.DeploymentService.apiDeploymentPost(this.model);
      snackbarModule.setSnackbarMessage("Deployment created");
      this.$emit("created");
    } catch {
      snackbarModule.setSnackbarMessage("Failed to create deployment");
    } finally {
      this.loading = false;
      this.syncedDialog = false;
    }
  }

  get filteredVenues(): Array<VenueModel> {
    if (this.venues.length > 0) {
      return this.venues.filter((x) => x.archived == false);
    }
    return [];
  }
}
